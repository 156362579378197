@import url(https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&family=Poppins:wght@400;500;700&display=swap);
.AnimatedText_animatedTextContainer__NiAtY {
  --maskX: 0;
  --maskY: 0;
  position: relative;
  cursor: default;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.AnimatedText_animatedTextContent__1zpHa {
  color: #000000;
  font-size: 100px;
  font-weight: 600;
  font-family: 'Graduate', cursive;
  line-height: 1.1;
  letter-spacing: 5px;
  text-shadow: 5px 3px 8px rgba(0, 0, 0, 0.2);
  margin: 0;
}

.AnimatedText_animatedTextContentClone__179TT {
  position: absolute;
  left: 0;
  top: 0;
  color: #ffffff;
  transition: all 0.4s ease-out;
  -webkit-clip-path: polygon(
    0 0,
    calc(var(--maskX) * 1% + (var(--maskY) - 50) * 0.4%) 0,
    calc(var(--maskX) * 1% + (var(--maskY) - 50) * -0.4%) 100%,
    0 100%
  );
          clip-path: polygon(
    0 0,
    calc(var(--maskX) * 1% + (var(--maskY) - 50) * 0.4%) 0,
    calc(var(--maskX) * 1% + (var(--maskY) - 50) * -0.4%) 100%,
    0 100%
  );
}

*{margin:0;padding:0;border:0;outline:0;font-family:'Roboto Condensed', sans-serif;letter-spacing:-0.03em;-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}html,body{font-size:62.5%;min-width:320px;overflow-x:hidden}.App{font-size:16px;font-weight:500;color:#666}.App header{height:100vh;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;-webkit-flex-direction:column;flex-direction:column;overflow:hidden}.App header h1{font-weight:700;font-size:7rem;text-transform:uppercase;font-family:"Poppins",sans-serif}.App header p{letter-spacing:3px;font-size:19px}

