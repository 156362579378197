@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&family=Poppins:wght@400;500;700&display=swap');
// poppins: 400, 500, 700
// PT Serif: 400, 700

$poppins: 'Poppins', sans-serif;
$pt: 'PT Serif', serif;
$blupurple: #3254ff;
$grey: #ecf0f1;
$darkGrey: #666666;

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: -0.03em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  font-size: 62.5%;
  min-width: 320px;
  overflow-x: hidden;
}

.App {
  font-size: 16px;
  font-weight: 500;
  color: $darkGrey;

  header {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    h1 {
      font-weight: 700;
      font-size: 7rem;
      text-transform: uppercase;
      font-family: $poppins;
    }

    p {
      letter-spacing: 3px;
      font-size: 19px;
    }
  }
}
